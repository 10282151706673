import {
	Checkbox, 
	CircularProgress,
	IconButton,
	Snackbar,
	SnackbarContent,
} from '@material-ui/core';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DeleteIcon from '@material-ui/icons/Delete';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import ImageModal from './modal/ImageModal';
import { VerinoteGreyFeature } from 'config/theme';
import React, { useEffect, useState } from 'react';
import { AttachmentDTO } from 'services';
import { useStore } from 'store';
import {getCookie, GetUrlExtension, MEDIA_TYPE_PREVIEW} from 'utils';
import {
	AttachFile,
	Headset,
	ImageRounded,
	PlayArrowRounded,
} from "@material-ui/icons";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			marginLeft: 'auto',
		},
		gridList: {
			flexWrap: 'nowrap',
			transform: 'translateZ(0)',
		},
		title: {
			color: theme.palette.primary.light,
		},
		titleBar: {
			background:
				'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
		},
		controlButton: {
			minWidth: '300px',
			display: 'flex',
			justifyContent: 'space-around',
			marginTop: '25px',
		},
		transcriptionLabel: {
			position: 'absolute',
			top: 0,
			right: 8,
			fontSize: '12px',
		}
	})
);

interface FileDisplayProps {
	file: AttachmentDTO;
	editing: boolean;
	selected?: boolean;
	exporting?: boolean;
	select?: (guid: string) => void;
}

export const FileDisplay = ({
	file,
	select,
	editing,
	exporting,
	selected,
}: FileDisplayProps) => {
	// members
	const name = `${file.fileName}`;
	const ext = GetUrlExtension(`${file.fileName}`);
	const contentType = ext.substring(0, ext.indexOf('/'));
	const canPreview = MEDIA_TYPE_PREVIEW.includes(contentType);
	// hooks
	const { fileStore, previewStore, noteStore } = useStore();
	const [blobUrl, setBlobUrl] = useState<string>("");
	const [showImage, setShowImage] = useState(false);
	const [showDownloadWarning, setShowDownloadWarning] = useState(false);
	const classes = useStyles();
	const [isLoading, setIsLoading] = useState(false);
	const {activeNote} = noteStore;
	
	useEffect(() => {
		if (showImage) {
			setIsLoading(false);
		}
	}, [showImage])
	
	// event handlers
	const downloadFile = () => {
		(async () => {
			setIsLoading(true);
			if (file.isVideo) {
				const uri = await fileStore.fetchPreviewVideo(`${file.attachmentGuid}`, getCookie("otp"), previewStore.previewPurpose);
				handleFileFetchResult(uri, uri);
			} else {
				const blob = await fileStore.fetchFile(`${file.attachmentGuid}`, getCookie("otp"), previewStore.previewPurpose);
				const newUrl = blob ? URL.createObjectURL(blob) : null;
				handleFileFetchResult(blob, newUrl);
			}
		})();
	};

	const handleFileFetchResult = (result: any, url: string | null) => {
		setIsLoading(false);
		if (result) {
			setBlobUrl(url!);
			setShowImage(true);
			previewStore.UpdateUUIDFile(file.attachmentGuid);
			previewStore.UpdatePreviewUrl(url!);
		} else {
			setIsLoading(true);
			previewStore.SetOpenPreview(() => {
				setShowImage(true);
				setBlobUrl(previewStore.previewUrl);
			});
			previewStore.TogglePreviewModal(file.attachmentGuid, file.isVideo);
		}
	};

	const viewAttachment = async () => {
		if (canPreview) {
			if (getCookie("otp")) {
				await downloadFile()
			} else {
				previewStore.SetHandleLoading(setIsLoading);
				setIsLoading(true)
				previewStore.SetOpenPreview(() => {
					setShowImage(true);
					setBlobUrl(previewStore.previewUrl)
				});
				previewStore.TogglePreviewModal(file.attachmentGuid, file.isVideo);
			}
			return;
		}

		// all other type need to be exported
		setShowDownloadWarning(true);
	};
	
	const IconContent = () => {
		if (canPreview) {
			switch (contentType) {
				case "image":
					return <ImageRounded fontSize="large" />
				case "video":
					return <PlayArrowRounded fontSize="large" />
				case "audio":
					return <Headset fontSize="large" />
				default:
					return <AttachFile fontSize="large" />
			}
		} else {
			return <AttachFile fontSize="large" />
		}
	}

	// render
	return (
		<GridListTile
			style={{
				minWidth: '200px',
				margin: 4,
				height: '200px',
				borderColor: VerinoteGreyFeature,
				borderStyle: 'dashed',
				borderRadius: '15px',
				padding: '5px',
				borderWidth: '2px',
				marginBottom: '4px',
			}}
			key={name}
		>
			<div className={classes.root}>
				{exporting && (
					<Checkbox
						checked={selected}
						color="primary"
						onClick={() => {
							if (select) {
								select(`${file.attachmentGuid}`);
							}
						}}
					/>
				)}
				<div className={classes.controlButton}>
					<IconButton onClick={viewAttachment}>
						{isLoading ? <CircularProgress /> : <IconContent />}
					</IconButton>
				</div>
			</div>

			<GridListTileBar
        style={{ borderRadius: '10px', height: '60px' }}
				title={ <span style={{whiteSpace: 'normal'}}>{name || ''}</span> }
			></GridListTileBar>

			<ImageModal
				open={showImage}
				onClose={setShowImage}
				blobUrl={blobUrl}
				type={contentType}
				attachmentId={file.attachmentGuid}
			/>

			<Snackbar
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				open={showDownloadWarning}
				onClose={() => setShowDownloadWarning(false)}
				autoHideDuration={5000}
			>
				<SnackbarContent message="This file type can only be downloaded via Export button" />
			</Snackbar>

			{(contentType === "audio" || contentType === "video") && activeNote?.attachments.find(it => it.attachmentGuid === file.attachmentGuid)?.hasTranscipt &&
        <span className={classes.transcriptionLabel}>
          Transcription Available
        </span>
      }

		</GridListTile>
	);
};
