/* eslint-disable no-console */
//import { observable, computed, action, runInAction } from "mobx";
import * as mime from 'mime';
import { AttachmentClient } from 'services';
import { RootStore } from 'store';
import {eraseCookie, parseBadRequestError, parseValidationError} from 'utils';
import BaseStore from './baseStore';
import {action, observable} from "mobx";

import { v4 as uuidv4 } from 'uuid';
export interface CreateUserInput {
	workGroup: string;
	interfaceTimeZone: string;
	organisationIdentifier: string;
	accessControlGroup: number;
}

export const FAIL = 'FAIL';
const CHUNK_FILE_SIZE = 10 * 1024 * 1024; // 10MB

export class FileStore extends BaseStore {
	private _attachmentClient: AttachmentClient;
	@observable updatePercentCallback: Function = (percent: number) => {};
	constructor(root: RootStore) {
		super(root);
		this._attachmentClient = new AttachmentClient(this.API_URL);
	}

	async fetchFile(guid: string, otp = "", reason = ""): Promise<Blob | null> {
		return this.AuthorisedRequest(this._attachmentClient, async () => {
			return this._attachmentClient
				.getPreviewFile(guid, otp, reason)
				.then((e) => {
					return e.data;
				})
				.catch((e) => {
					if (e.status === 419) {
						eraseCookie("otp");
						this._rootStore.previewStore.TogglePreviewModal();
					} else {
						this.error = "Get file failed";
					}
					this._rootStore.submitExceptionToAppInsight(e);
					return null;
				});
		});
	}

	async fetchPreviewVideo(guid: string, otp = "", reason = ""): Promise<string | null> {
		return this.AuthorisedRequest(this._attachmentClient, async () => {
			return this._attachmentClient
					.getPreviewVideo(guid, otp, reason)
					.then((e) => {
						return e;
					})
					.catch((e) => {
						if (e.status === 419) {
							eraseCookie("otp");
							this._rootStore.previewStore.TogglePreviewModal();
						} else {
							this.error = "Get file failed";
						}
						this._rootStore.submitExceptionToAppInsight(e);
						return null;
					});
		});
	}

	async fetchZipfile(filename: string): Promise<Blob | null> {
		return this.AuthorisedRequest(this._attachmentClient, async () => {
			return this._attachmentClient
				.getZipFile(filename)
				.then((e) => {
					return e.data;
				})
				.catch((e) => {
					this._rootStore.submitExceptionToAppInsight(e);
					return null;
				});
		});
	}

	@action async uploadFile(file: Blob, name: string, activeNoteId: number): Promise<{id: string, filename: string}> {
		return new Promise(((resolve, reject) => {
			this.AuthorisedRequest(this._attachmentClient, async () => {
				// try detect file type File picker file type first
				const ext = mime.getExtension(file.type);
				//generate uuid for attachmentId
				const attachmentId = uuidv4();
				const fileName = ext ? `${name}.${ext}` : name;
				let totalChunks = Math.ceil(file.size / CHUNK_FILE_SIZE);
				for (let i = 0; i < totalChunks; i++) {
					const start = i * CHUNK_FILE_SIZE;
					const end = Math.min(start + CHUNK_FILE_SIZE, file.size);
					const chunk = file.slice(start, end);
					await this._attachmentClient.create(
						activeNoteId,
						fileName,
						false,
						null,
						null,
							totalChunks,
							i,
							attachmentId,
						{ fileName: `${name}`, data: chunk }
					).then((res) => {
						const percentUpload = Math.floor((i / (totalChunks - 1)) * 100)
						this.updatePercentCallback(percentUpload);
						if (i === totalChunks - 1) {
							this.loading = false;
							this.success = `File uploaded successfully`;
							resolve({id: res, filename: fileName});
						}
					}).catch((e) => {
						this._rootStore.submitExceptionToAppInsight(e);
						const validation = parseValidationError(e);
						this.loading = false;

						if (validation && validation.FileName) {
							this.error = 'File format or extension is not valid';
						} else {
							this.error = parseBadRequestError(e) || 'Something went wrong with your file upload';
						}
						reject();
					});
				}
			});
		}));
	}
	
	@action
	setCallBackPercentUpload(callback: Function) {
		this.updatePercentCallback = callback;
	}
}

export default FileStore;
