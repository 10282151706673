import {Box, Button, CircularProgress, Typography} from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { observer } from 'mobx-react-lite';
import React, {useState} from 'react';
import {useStore} from "../../store";
import {getCookie} from "../../utils";

// styles
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		imgViewer: {
			maxWidth: '100%',
			// height: '100%',
			maxHeight: '80vh',
		},
		paper: {
			maxWidth: '90%',
			width: 'auto',
			maxHeight: '90%',
			backgroundColor: theme.palette.background.paper,
			border: '2px solid #000',
			boxShadow: theme.shadows[5],
			padding: 10,
			paddingInline: 50,
			textAlign: 'center',
			alignSelf: 'center',
		},
		buttonContainer: {
			display: 'flex',
			justifyContent: 'end',
		},
	})
);

interface ImageModalProps {
	open: boolean;
	onClose: any;
	blobUrl?: string;
	type: string;
	attachmentId: string;
}

const ImageModal = ({ open, onClose, blobUrl, type, attachmentId }: ImageModalProps) => {
	const classes = useStyles();
	const { transcribeStore, noteStore } = useStore();
	const {activeNote} = noteStore;
	const [loading, setLoading] = useState<boolean>();
	
	const openTranscriptModal = async () => {
		setLoading(true);
		await transcribeStore.getTranscript(attachmentId);
		setLoading(false);
		transcribeStore.setBlobUrl(blobUrl);
		onClose(false);
	}

	return (
		<Modal
			aria-labelledby="simple-modal-title"
			aria-describedby="simple-modal-description"
			open={open}
			style={{display:'flex', alignItems:'center', justifyContent:'center'}}
		>
			<Box flexDirection="row" className={classes.paper} >
				<Typography>
					This media can be securely downloaded via Export button
				</Typography>
				<Box>
					{
							type === "image" &&
							<img
									alt="uploaded-file"
									className={classes.imgViewer}
									onContextMenu={(e) => e.preventDefault()}
									src={blobUrl}
							/>
					}
					{
							type === "audio" &&
							<audio
									controlsList="nodownload"
									className={classes.imgViewer}
									onContextMenu={(e) => e.preventDefault()}
									key={blobUrl}
									controls
							>
								<source src={blobUrl} type="audio/wav"/>
							</audio>
					}
					{
							type === "video" &&
							<video
									controlsList="nodownload"
									className={classes.imgViewer}
									onContextMenu={(e) => e.preventDefault()}
									key={blobUrl}
									controls
							>
								<source src={blobUrl} type="video/mp4"/>
							</video>
					}
				</Box>
				<div className={classes.buttonContainer}>
					{(type === "audio" || type === "video") && activeNote?.attachments.find(it => it.attachmentGuid === attachmentId)?.hasTranscipt &&
					<Button
						onClick={openTranscriptModal}
						disabled={loading}
						startIcon={loading ? <CircularProgress size={15}/> : null}
					>
						View Transcription
					</Button>}
					<Button onClick={() => onClose(false)}>Close</Button>
				</div>
			</Box>
		</Modal>
	);
};

export default observer(ImageModal);
